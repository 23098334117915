import React from 'react';
import { makeStyles } from '@material-ui/core';

// const useStyles = makeStyles({
//   svg: {
//     width: 'auto',
//     height: 28,
//   },
//   path: {
//     fill: '#7df3e1',
//   },
// });
const useStyles = makeStyles({
  svg: {
    height: 30,
    width: 'auto',
  },

  st1: { 
    fill: 'rgb(2, 102, 174)'
  },
  st2: {
    'fill-rule': 'evenodd',
    'clip-rule': 'evenodd',
    fill: 'rgb(255, 221, 48)'
  },
});

const LogoIcon = () => {
  const classes = useStyles();

  return (
    <svg 
    viewBox="0.817 0.649 61.708 46.9" 
    className={classes.svg}
    xmlns="http://www.w3.org/2000/svg">
      <path
        id="XMLID_1_"
        className={classes.st1}
        d="M -163.832 -104.094 L -221.732 -104.094 C -222.832 -104.094 -223.632 -103.294 -223.632 -102.194 L -223.632 -59.094 C -223.632 -57.994 -222.732 -57.194 -221.732 -57.194 L -163.832 -57.194 C -162.732 -57.194 -161.932 -58.094 -161.932 -59.094 L -161.932 -102.194 C -161.832 -103.294 -162.732 -104.094 -163.832 -104.094 Z"
        transform="matrix(1, 0, 0, 1, 224.44902, 104.743042)"
      />
      <path
        id="XMLID_37_"
        className={classes.st2}
        d="M -168.432 -80.794 L -199.532 -80.794 C -199.432 -81.294 -199.332 -81.794 -199.332 -82.394 C -199.332 -85.394 -201.732 -87.794 -204.732 -87.794 C -207.332 -87.794 -209.432 -85.994 -210.032 -83.594 C -210.532 -83.794 -211.032 -83.794 -211.632 -83.794 C -214.632 -83.794 -217.032 -81.394 -217.032 -78.394 C -217.032 -75.394 -214.632 -72.994 -211.632 -72.994 C -211.132 -72.994 -210.532 -73.094 -210.032 -73.194 C -209.432 -70.794 -207.332 -68.994 -204.732 -68.994 C -201.732 -68.994 -199.332 -71.394 -199.332 -74.394 C -199.332 -74.894 -199.432 -75.494 -199.532 -75.994 L -178.432 -75.994 L -178.432 -69.494 L -175.932 -69.494 L -175.932 -72.794 L -173.532 -72.794 L -173.532 -69.494 L -171.032 -69.494 L -171.032 -75.994 L -168.532 -75.994 L -168.532 -80.794 L -168.432 -80.794 Z M -204.732 -84.794 C -203.432 -84.794 -202.332 -83.694 -202.332 -82.394 C -202.332 -81.094 -203.432 -79.994 -204.732 -79.994 C -206.032 -79.994 -207.132 -81.094 -207.132 -82.394 C -207.232 -83.694 -206.132 -84.794 -204.732 -84.794 Z M -214.032 -78.394 C -214.032 -79.694 -212.932 -80.794 -211.632 -80.794 C -210.332 -80.794 -209.232 -79.694 -209.232 -78.394 C -209.232 -77.094 -210.332 -75.994 -211.632 -75.994 C -212.932 -75.994 -214.032 -77.094 -214.032 -78.394 Z M -204.732 -72.094 C -206.032 -72.094 -207.132 -73.194 -207.132 -74.494 C -207.132 -75.794 -206.032 -76.894 -204.732 -76.894 C -203.432 -76.894 -202.332 -75.794 -202.332 -74.494 C -202.332 -73.194 -203.432 -72.094 -204.732 -72.094 Z"
        transform="matrix(1, 0, 0, 1, 224.44902, 104.743042)"
      />
    </svg>
  );
};

export default LogoIcon;
