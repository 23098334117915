import { TechRadarPageProps } from "@backstage/plugin-tech-radar"

const CoppelTechRadarPageProps: TechRadarPageProps = {
    width: 1500,
    height: 800,
    title:'Tech Radar Coppel',
    subtitle: 'Pick the recommended technologies for your projects',
    pageTitle: 'Company Radar'
    
}

export default CoppelTechRadarPageProps;