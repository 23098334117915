import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    height: 30,
    width: 'auto',
  },
  path: {
    fill: '#7df3e1',
  },
  st0: { fill: '#FFDD30' },
  st1: { fill: '#0266AE' },
  st2: {
    'fill-rule': 'evenodd',
    'clip-rule': 'evenodd',
    fill: '#FFDD30',
  },
});
const LogoFull = () => {
  const classes = useStyles();

  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      //   xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 243.1 50.2"
      className={classes.svg}
      //   xml:space="preserve"
    >
      <style type="text/css"></style>
      <g id="XMLID_30_">
        <path
          id="XMLID_2_"
          className={classes.st0}
          d="M238.2,2.3H69.7c-1.1,0-1.9,0.9-1.9,1.9v43.1c0,1.1,0.9,1.9,1.9,1.9h168.5
		c1.1,0,1.9-0.9,1.9-1.9V4.2C240.1,3.1,239.3,2.3,238.2,2.3z"
        />
        <path
          id="XMLID_1_"
          className={classes.st1}
          d="M63,2.3H5.1C4,2.3,3.2,3.1,3.2,4.2v43.1c0,1.1,0.9,1.9,1.9,1.9H63c1.1,0,1.9-0.9,1.9-1.9V4.2
		C65,3.1,64.1,2.3,63,2.3z"
        />
        <g id="XMLID_42_">
          <path
            id="XMLID_55_"
            className={classes.st1}
            d="M124.1,18.4c-6.2,0-11.3,4.4-11.3,10.9c0,6.5,5.1,10.9,11.3,10.9c6.2,0,11.3-4.4,11.3-10.9
			C135.4,22.9,130.3,18.4,124.1,18.4z M124.2,35.8C124.2,35.8,124.2,35.8,124.2,35.8c-0.1,0-0.1,0-0.1,0c-3.8,0-6.4-2.9-6.4-6.4
			c0-3.5,2.6-6.4,6.4-6.4c0,0,0,0,0.1,0c0,0,0,0,0.1,0c3.8,0,6.4,2.9,6.4,6.4C130.6,32.9,128,35.8,124.2,35.8z"
          />
          <path
            id="XMLID_54_"
            className={classes.st1}
            d="M108.1,31.9c-1.9,2.2-4.7,3.7-7.8,3.7c-5.6,0-10.2-4.6-10.2-10.2c0-5.6,4.6-10.2,10.2-10.2
			c3.1,0,5.9,1.4,7.7,3.6l3.5-3.1c-2.8-3.2-6.9-5.2-11.4-5.2c-8.3,0-15.1,6.6-15.1,14.8c0,8.2,6.8,14.9,15.1,14.9
			c4.6,0,8.7-2,11.5-5.2L108.1,31.9z"
          />
          <g id="XMLID_43_">
            <path
              id="XMLID_51_"
              className={classes.st1}
              d="M173,18.4c-6.2,0-11.3,4.5-11.3,10.9c0,6.4,0,19.8,0,19.8h4.9V38.4c1.8,1.2,4.1,1.8,6.4,1.8
				c6.2,0,11.3-4.5,11.3-10.9C184.3,22.9,179.2,18.4,173,18.4z M173.1,35.8C173,35.8,173,35.8,173.1,35.8c-0.1,0-0.1,0-0.1,0
				c-3.8,0-6.4-2.9-6.4-6.4c0-3.5,2.6-6.4,6.4-6.4c0,0,0,0,0.1,0s0,0,0.1,0c3.8,0,6.4,2.9,6.4,6.4C179.4,32.9,176.8,35.8,173.1,35.8
				z"
            />
            <path
              id="XMLID_48_"
              className={classes.st1}
              d="M148.6,18.4c-6.2,0-11.3,4.5-11.3,10.9c0,6.4,0,19.8,0,19.8h4.9V38.4
				c1.8,1.2,4.1,1.8,6.4,1.8c6.2,0,11.3-4.5,11.3-10.9C159.9,22.9,154.7,18.4,148.6,18.4z M148.6,35.8
				C148.6,35.8,148.6,35.8,148.6,35.8c-0.1,0-0.1,0-0.1,0c-3.8,0-6.4-2.9-6.4-6.4c0-3.5,2.6-6.4,6.4-6.4c0,0,0,0,0.1,0s0,0,0.1,0
				c3.8,0,6.4,2.9,6.4,6.4C155,32.9,152.4,35.8,148.6,35.8z"
            />
            <path
              id="XMLID_45_"
              className={classes.st1}
              d="M196,18.4c-5.7,0-10.5,4.5-10.5,10.9c0,6.5,4.8,10.9,10.5,10.9c3.4,0,7.1-1.1,9-3.8l-2.9-2.9
				c-1.3,1.4-3.2,2.3-5.5,2.3c-3.8,0-6.3-2.2-6.3-5.4h16.1c0-0.4,0.1-0.8,0.1-1.3C206.5,22.9,202.4,18.4,196,18.4z M190.4,27.2
				c0-1.9,2.5-4.6,5.9-4.6c2.2,0,5.2,1.7,5.2,4.6H190.4z"
            />
            <path
              id="XMLID_44_"
              className={classes.st1}
              d="M213.2,29.3V10.9h-4.9v18.4l0,0c0,6.4,5,10.8,11.1,10.9v-4.5
				C215.7,35.7,213.2,32.8,213.2,29.3z"
            />
          </g>
        </g>
        <path
          id="XMLID_37_"
          className={classes.st2}
          d="M58.4,25.6H27.3c0.1-0.5,0.2-1,0.2-1.6c0-3-2.4-5.4-5.4-5.4c-2.6,0-4.7,1.8-5.3,4.2
		c-0.5-0.2-1-0.2-1.6-0.2c-3,0-5.4,2.4-5.4,5.4c0,3,2.4,5.4,5.4,5.4c0.5,0,1.1-0.1,1.6-0.2c0.6,2.4,2.7,4.2,5.3,4.2
		c3,0,5.4-2.4,5.4-5.4c0-0.5-0.1-1.1-0.2-1.6h21.1v6.5h2.5v-3.3h2.4v3.3h2.5v-6.5h2.5V25.6z M22.1,21.6c1.3,0,2.4,1.1,2.4,2.4
		c0,1.3-1.1,2.4-2.4,2.4c-1.3,0-2.4-1.1-2.4-2.4C19.6,22.7,20.7,21.6,22.1,21.6z M12.8,28c0-1.3,1.1-2.4,2.4-2.4
		c1.3,0,2.4,1.1,2.4,2.4c0,1.3-1.1,2.4-2.4,2.4C13.9,30.4,12.8,29.3,12.8,28z M22.1,34.3c-1.3,0-2.4-1.1-2.4-2.4
		c0-1.3,1.1-2.4,2.4-2.4c1.3,0,2.4,1.1,2.4,2.4C24.5,33.2,23.4,34.3,22.1,34.3z"
        />
        <g id="XMLID_31_">
          <path
            id="XMLID_32_"
            className={classes.st1}
            d="M225.4,38c0,1.3-1,2.3-2.3,2.3c-1.3,0-2.3-1-2.3-2.3c0-1.2,1-2.2,2.3-2.2
			C224.4,35.8,225.4,36.8,225.4,38z M221.4,38c0,1,0.7,1.8,1.7,1.8c1,0,1.7-0.8,1.7-1.8c0-1-0.7-1.8-1.7-1.8
			C222.1,36.2,221.4,37.1,221.4,38z M222.8,39.2h-0.5V37c0.2,0,0.5-0.1,0.9-0.1c0.4,0,0.6,0.1,0.8,0.2c0.1,0.1,0.2,0.3,0.2,0.5
			c0,0.2-0.2,0.4-0.5,0.5v0c0.2,0.1,0.3,0.2,0.4,0.5c0.1,0.3,0.1,0.5,0.2,0.6h-0.6c-0.1-0.1-0.1-0.3-0.2-0.5c0-0.2-0.2-0.4-0.5-0.4
			h-0.2V39.2z M222.8,37.9h0.2c0.3,0,0.5-0.1,0.5-0.3c0-0.2-0.2-0.3-0.5-0.3c-0.1,0-0.2,0-0.3,0V37.9z"
          />
        </g>
      </g>
    </svg>
  );
};

export default LogoFull;
